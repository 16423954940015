import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '../../src/assets/config/auth_config.json';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url = `${environment.apiDomain}/${environment.appName}/${environment.verion}`
  rawIdToken: any;
  idTokenData: any
  accessTokenData: any
  constructor(private http: HttpClient, private auth: AuthService) { }
  ngOnInit() {
    if (this.auth.isAuthenticated$) {
      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken.
          this.rawIdToken = idToken.__raw
        console.log('idToken', this.idTokenData)



        this.auth.getAccessTokenSilently().subscribe(accessToken => {
          this.accessTokenData = accessToken
          console.log('accessToken', this.accessTokenData)
        });

      });
    }
  }

  ping$(): Observable<any> {
    console.log(config.apiUri);
    return this.http.get(`${config.apiUri}/api/external`);
  }

  // public getToken() {
  //   this.auth.idTokenClaims$.subscribe(idToken => {
  //     console.log('ID Token:', idToken);
  //     this.auth.getAccessTokenSilently().subscribe(accessToken => {
  //       console.log('Access Token:', accessToken);
  //     });
  //   });


  // }
  public getProjectList(params:any) {
    return this.http.get(`${this.url}/project`,{params:params})
  }

  public getProjectListByClient(params: any) {
    return this.http.get(`${this.url}/project`, { params })
  }

  public getUserInfo(email: string) {
    const idToken = this.idTokenData;
    const accessToken = this.accessTokenData;

    let params = new HttpParams().set('username', email)
    return this.http.get(`${this.url}/user`, { params })
  }

}
