<div class="wrapper">
  <div class="left-search-panel">
    <div class="left-search-panel-filters">
      <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input [(ngModel)]="enteredSearchValue" (keyup)="onSearchTextChanged()" matInput type="text"
          placeholder="search project here" class="search-input" />
      </mat-form-field>
      <!-- <mat-form-field>
        <mat-label>Select Coordinator</mat-label>
        <mat-select [(ngModel)]="CoordinatorSelected" (ngModelChange)="onCoordinatorSelected()">
          <mat-option *ngFor="let coordinator of Coordinator" [value]="coordinator._id">
            {{ coordinator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field>
        <mat-label>Select State</mat-label>
        <mat-select [(ngModel)]="StateSelected" (ngModelChange)="onStateSelected()">
          <mat-option *ngFor="let state of States" [value]="state.name">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field>
        <mat-label>From-To</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onStartDateChanged()" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onStartDateChanged()" />
        </mat-date-range-input>

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field> -->
    </div>
  </div>

  <div class="right-search-panel">
    <div class="filter-section">
      <!-- <div class="filter-box">
        <img
          class="icon"
          src="assets/images/filter-icon.svg"
        />
        <label class="filter-count">12</label>
      </div>

      <span class="space-between">Filters</span>
    </div> -->
    </div>
    <div class="applied-filters" *ngIf="
        CoordinatorSelected ||
        ClientSelected ||
        StateSelected ||
        PaymentSelected ||
        enteredSearchValue ||
        create_date_gt ||
        create_date_lt ||
        roleSelected
      ">
      Applied Filters:
      <span *ngIf="CoordinatorSelected" (click)="clearIndividual('coordinator')">Coordinators
        <mat-icon>clear</mat-icon></span>
      <span *ngIf="ClientSelected" (click)="clearIndividual('client')">
        Client <mat-icon>clear</mat-icon></span>
      <span *ngIf="StateSelected" (click)="clearIndividual('state')">
        States <mat-icon>clear</mat-icon></span>
      <span *ngIf="PaymentSelected" (click)="clearIndividual('')">
        Payment <mat-icon>clear</mat-icon></span>
      <span *ngIf="enteredSearchValue" (click)="clearIndividual('searchvalue')">
        search Filter <mat-icon>clear</mat-icon></span>
      <span *ngIf="create_date_gt" (click)="clearIndividual('startDate')">Start Date <mat-icon>clear</mat-icon></span>
      <span *ngIf="create_date_lt" (click)="clearIndividual('endDate')">
        End Date <mat-icon>clear</mat-icon></span>
      <span *ngIf="roleSelected" (click)="clearIndividual('coordinatorRole')">Role <mat-icon>clear</mat-icon></span>
      <button class="clear-filter-btn" (click)="clearFilter()">Clear</button>
    </div>
    <button class="svagriha" (click)="move()">Svagriha Projects</button>
  </div>
</div>


<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- Project Details Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef>Project Details</th>
      <td mat-cell *matCellDef="let element">
        <div>
          {{ element.name }}
        </div>
        <!-- <div>{{element?.project?.org}} </div> -->
        <div>Version: <strong>{{element?.app?.name}}</strong></div>
      </td>
    </ng-container>

    <!-- Client Deatils Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client Details</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.client.name }}</div>
        <div>{{ element.client.state }}</div>
      </td>
    </ng-container>

    <!-- Registered Date Column -->
    <ng-container matColumnDef="regDate">
      <th mat-header-cell *matHeaderCellDef>Registered Date</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.created_at }}</div>
      </td>
    </ng-container>

    <!-- Proj Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <div>Project : {{ element.status }}</div>
        <div *ngIf="element.payment_status?.status; else noRecord">
          Payment: {{ element.payment_status.status }}
        </div>
        <div *ngIf="element.workshop_status?.status; else noRecord">
          Workshop: {{ element.workshop_status.status }}
        </div>
        <div *ngIf="element.site_visit_status?.status; else noRecord">
          Site Visit: {{ element.site_visit_status.status }}
        </div>
      </td>
    </ng-container>

    <!-- Proj Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.type }}</div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="coordinator">
      <th mat-header-cell *matHeaderCellDef>Coordinator</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.coordinator?.name; else noRecord">
          {{ element.coordinator.name }}
        </div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="rowData(element)">view</button>
      </td>
    </ng-container>
    <ng-template #noRecord>
      <p>NA</p>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Projects"
    [length]="totalItems" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="onPageChange($event)">
  </mat-paginator>
</div>