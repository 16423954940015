import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';


@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor() { }

  ngOnInit() {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');

    console.log("idToken", idToken)
    console.log("accessToken", accessToken)

    const authRequest = request.clone({
      setHeaders: {
        id_token: idToken,
        Authorization: `Bearer ${accessToken}`,
      }
    })
    return next.handle(authRequest);
  }
}
