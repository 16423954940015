<div class="nav-container">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container">

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a routerLink="/" class="nav-link">Home</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <a routerLink="external-api" class="nav-link">External API</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <button class="btn btn-link p-0" style="min-width: unset" id="qsLogoutBtn" (click)="logout()">
              Log out
            </button>
          </li>
        </ul>

        <ul class="navbar-nav d-none d-md-block">
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) === false">
            <button id="qsLoginBtn" class="btn btn-primary btn-margin" (click)="loginWithRedirect()">
              Log in
            </button>
          </li>

          <li class="nav-item dropdown" *ngIf="auth.user$ | async as user" ngbDropdown>
            <a ngbDropdownToggle class="nav-link dropdown-toggle" id="profileDropDown" data-toggle="dropdown">
              <img [src]="user.picture" alt="Profile picture" class="nav-user-profile rounded-circle"
                style="width: 75px" />
            </a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                {{ user.name }}
              </div>
              <a routerLink="/profile" class="dropdown-item dropdown-profile">
                <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
              </a>
              <button (click)="logout()" class="btn btn-link dropdown-item">
                <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
              </button>
            </div>
          </li>
        </ul>

        <!-- <ul
          class="navbar-nav d-md-none"
          *ngIf="(auth.isAuthenticated$ | async) === false"
        >
          <button
            class="btn btn-primary btn-block"
            id="qsLoginBtn"
            (click)="loginWithRedirect()"
          >
            Log in
          </button>
        </ul> -->

        <ul class="navbar-nav d-md-none justify-content-between" *ngIf="auth.user$ | async as user"
          style="min-height: 170px">
          <li class="nav-item">
            <span class="user-info">
              <img alt="Profile picture" class="nav-user-profile d-inline-block rounded-circle mr-3" style="width: 75px"
                [src]="user.picture" />

              <h6 class="d-inline-block">{{ user.name }}</h6>
            </span>
          </li>
          <li>
            <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
            <a routerLink="/profile">Profile</a>
          </li>

          <li>
            <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
            <button class="btn btn-link p-0" (click)="logout()">Log out</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>