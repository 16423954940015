import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import {RegisterProjectComponent} from '../register-project/register-project.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  constructor(public auth: AuthService,private dialog: MatDialog) { }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  registerProject(){
    this.dialog.open(RegisterProjectComponent),{
      width: '400px', // Specify the width here (you can use pixels or percentages)
      height: '300px' 
    }
  }
}
