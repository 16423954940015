<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <img class="image" src="/assets/newlogin.jpg" alt="image" />
  <div class="button-container">
    
    <button class="login-button" (click)="loginWithRedirect()"
    >
    Log in
  </button>
  <button class="register-button" (click)="registerProject()">Register Project</button>
</div>
</ng-template>
