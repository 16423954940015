import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterServiceService {
  url = `${environment.apiDomain}/${environment.appName}/${environment.verion}`
  private ROLE_URL = '/role';
  private STATE_URL = '/state';
  private CITY_URL='/app/city'
  private USER_ROLE_COORDINATOR_URL = '/user?role=Coordinator';
  private USER_ROLE_CLIENT_URL = '/user?role=Client';
  private FEE_STATE_URL = '/fee/status';
  private USER_PROFILE_PIC_URL = '/user?_id='
  private profilePostUrl = '/user/profilepic/';
  private CRITERIA_URL='/criteria/#';
  private CRITERIA_By_Project_URL='/criteria/#';
  private SITEVISIT_REPORT_URL='/sitevisitscheduling/#/pdfdetails'
  private UPDATE_BUILDING_URL='/project/#'


  constructor(private httpClient: HttpClient) {

  }

  //Get List of Roles
  public getRole() {
    return this.httpClient.get(this.url+this.ROLE_URL);
  }
  //Get list of States
  public getStates() {
    return this.httpClient.get(this.url+this.STATE_URL);
  }

  public getCity(){
    return this.httpClient.get(this.url+this.CITY_URL)
  }

  public getCoordinator() {
    return this.httpClient.get(this.url+this.USER_ROLE_COORDINATOR_URL)
  }
  public getEvaluator() {
    return this.httpClient.get(this.url+`/user?role=Evaluator`)
  }

  public getClient() {
    return this.httpClient.get(this.url+this.USER_ROLE_CLIENT_URL)
  }
  public getPayment() {
    return this.httpClient.get(this.url+this.FEE_STATE_URL)
  }

  // Profile Pic for Admin
  public getProfilePic(_id: any) {
    return this.httpClient.get(this.url+`${this.USER_PROFILE_PIC_URL}${_id}`)
  }

  public postProfileId(_id: any) {
    return this.httpClient.post(`${this.profilePostUrl}${_id}`, {})
  }

  public uploadProfilePic(url: string, body: any) {
    return this.httpClient.put(url, body)
  }

  public byPassCriteria(projectId:string,body:any){
    return this.httpClient.patch(this.CRITERIA_By_Project_URL.replace('#',projectId),body);
  }

  public getSiteVisitReport(projectId:string){
    return this.httpClient.get(this.SITEVISIT_REPORT_URL.replace('#',projectId))
  }

  // API to get criterion
  public getCriteriaData(project_id:string){
    
    return this.httpClient.get(this.CRITERIA_URL.replace('#',project_id))
  }
  public updateCriteriaAssociativity(project_id: string, body: any) {
    return this.httpClient.patch(this.CRITERIA_URL.replace('#',project_id), body)
  }

  updateBuildingDetails(body:any,project_code:string){
    return this.httpClient.patch(this.UPDATE_BUILDING_URL.replace('#',project_code),body);
  }

  private handleError = (error: any) => {
    let errorMessage = '';
    if (error?.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error?.error?.error;
    } else {
      // server-side error
      errorMessage = error?.error?.error;
    }
    return throwError(errorMessage);
  }
}
