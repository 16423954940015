import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { PeriodicElement } from '../project-list/project-list.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-evaluator',
  templateUrl: './evaluator.component.html',
  styleUrls: ['./evaluator.component.css'],
})
export class EvaluatorComponent {
  @Input() dataSource: MatTableDataSource<PeriodicElement>;
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canViewLogs: boolean;
  displayedColumns: string[] = [
    'project',
    'client',
    'regDate',
    'status',
    'type',
    'coordinator',
    'actions',
  ];
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  datas: any;
  redirectUrl = environment.redirect.evaluatorRedirectUrl;
  idTokenData: any;
  accessTokenData: any;
  userInfo: any;
  role: any;
  emailId: string;
  projectCode: any = 'sadwqe12';
  clientId: any = '6471e0bdaedbd8b065d02875';
  evaluatorInfo: any;

  
  pageIndex: number = 0;
  pageSize: number = 5;
  totalItems: number = 0;

  
  @ViewChild(MatPaginator) matPaginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  
  data: any;

  constructor(
    private auth: AuthService,
    private service: ApiService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem('evaluatorInfo')) {
      this.evaluatorInfo = localStorage.getItem('evaluatorInfo');
      
      const params={
        page:(this.pageIndex+1).toString(),
        list_size:this.pageSize.toString(),
        status:'INITIATED',
        evaluator:this.evaluatorInfo
      }
      this.getProjectList(params);
      this.service.getProjectListByClient(params).subscribe((data) => {
        this.datas = data;
        this.dataSource = this.datas.data;

        this.auth.idTokenClaims$.subscribe((idToken) => {
          this.idTokenData = idToken.__raw;
          console.log(this.idTokenData);

          this.auth.getAccessTokenSilently().subscribe((accessToken) => {
            this.accessTokenData = accessToken;
          });
        });
      });
    } else {
      window.location.href = environment.logoutUrl;
    }
  }

  getProjectList(params:any){
    console.log(params);
    this.dataSource=new MatTableDataSource();
    this.service.getProjectListByClient(params).subscribe((data) => {
      this.data = data

      this.totalItems=this.data.totalItems;
      this.dataSource = this.data.data;
      
      if (this.matPaginator) {
        this.dataSource.paginator = this.matPaginator;
      }
      this.dataSource.sort = this.matSort;

      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken.__raw
        console.log(this.idTokenData)

        this.auth.getAccessTokenSilently().subscribe(accessToken => {
          this.accessTokenData = accessToken
        });
      })

    });

  }


  onPageChange(event:PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params={
      page:this.pageIndex+1,
      list_size:this.pageSize,
      status:'INITIATED',
      role:'Coordinator'
    }
    this.getProjectList(params);
  }

  onDelete = (rowData: any) => {
    this.delete.emit(rowData);
    console.log(rowData);
  };

  onEdit = (rowData: any) => {
    this.edit.emit(rowData);
  };

  onView = (rowData: any) => {
    this.view.emit(rowData);
  };
  onInitiate = (rowData: any) => {
    this.initiate.emit(rowData);
  };

  onAssignUser = (rowData: any) => {
    this.assignUser.emit(rowData);
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  move() {
    let redirectLocation = `${this.redirectUrl}`;
    window.location.href = `${redirectLocation}`;
  }

  rowData(e: any) {
    console.log(e);
    console.log(e.project_code);
    let redirectLocation = `${this.redirectUrl}/section/${e.project_code}/project-details/`;
    console.log(redirectLocation);
    window.location.href = `${redirectLocation}`;
  }
}
