import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ClientComponent } from './components/client/client.component';
import { CoordinatorComponent } from './components/coordinator/coordinator.component';
import { EvaluatorComponent } from './components/evaluator/evaluator.component';
import { SuperAdminComponent } from './components/super-admin/super-admin.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-api',
    component: ExternalApiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'admin',
    component: ProjectListComponent

  },
  {
    path: 'client',
    component: ClientComponent

  },
  {
    path: 'coordinator',
    component: CoordinatorComponent
  },
  {
    path:'evaluator',
    component:EvaluatorComponent
  },
  {
    path:'super-admin',
    component:SuperAdminComponent
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
