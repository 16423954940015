import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-project',
  templateUrl: './register-project.component.html',
  styleUrls: ['./register-project.component.css'],
})
export class RegisterProjectComponent {
  redirectUrl = environment.redirect.old_panel_base_url;
  New_Building = "/Project/Create"
Existing_Building = "/Project/Create?type=eb"
Existing_Day_Schools = "/Project/Create?type=s"
GRIHA_for_Cities = "/Project/Create?type=sc"

  redirectToNB() {
    alert(`this feature will come soon. Link:-${this.redirectUrl + this.New_Building }`)
    // window.location.href = `${redirectLocation}`;
  }

  redirectToEB() {
    alert(`this feature will come soon. Link:-${this.redirectUrl +this.Existing_Building }`)
  }

  redirectToEDS() {
    alert(`this feature will come soon. Link:-${this.redirectUrl +this.Existing_Day_Schools }`)
  }
  redirectToGFC() {
    alert(`this feature will come soon. Link:-${this.redirectUrl +this.GRIHA_for_Cities }`)
  }
}
