import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { response } from 'express';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { FilterServiceService } from 'src/app/services/filter-service.service';
import { environment } from 'src/environments/environment';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css']
})
export class SuperAdminComponent implements AfterViewInit {

  @Output() searchTextChanged = new EventEmitter<string>();
  @Input() onStatusChange: any;

  roles: [];
  datas: any;
  CoordinatorSelected: string;
  roleSelected: string;
  ClientSelected: string;
  StateSelected: string;
  PaymentSelected: string;
  States: [any];
  Coordinator: [any];
  Client: [any];
  Payment: [];
  create_date_gt: string;
  create_date_lt: string;
  startDate: any;
  endDate: any;
  EndDate: any;
  result: any;
  userRole: any;
  userId: any;
  coordinatorRole: any = [
    'All',
    'My Projects as Coordinator',
    'Site Visit as QC',
    'Site Visit as Coordinator',
    'Workshop as Coordinator',
  ];
  selectedFiles: File[];
  enteredSearchValue: string = '';
  private searchTextSubject = new Subject<string>();


  @Input() dataSource: MatTableDataSource<PeriodicElement>;
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canViewLogs: boolean;
  displayedColumns: string[] = ['project', 'client', 'regDate', 'status', 'type', 'coordinator', 'actions'];
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: any;
  redirectUrl = environment.redirect.superAdminRedirectUrl
  watergrihaUrl = environment.redirect.water_griha_url;
  idTokenData: any;
  accessTokenData: any;
  userInfo: any;
  role: any;
  emailId: string;
  projectCode: any = 'sadwqe12'
  
  pageIndex: number = 0;
  pageSize: number = 5;
  totalItems: number = 0;

  
  @ViewChild(MatPaginator) matPaginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;

  constructor(private auth: AuthService,
    private apiService: ApiService, private http: HttpClient, private router: Router,
    private service: FilterServiceService) {
    
    this.service.getRole().subscribe((data) => {
      this.datas = data;
      this.roles = this.datas.data.roles;
    });
    this.service.getStates().subscribe((data) => {
      this.datas = data;
      this.States = this.datas.data;
    });
    this.service.getCoordinator().subscribe((data) => {
      this.datas = data;
      this.Coordinator = this.datas.data;
      console.log('coordinator', this.Coordinator);
    });
    this.service.getClient().subscribe((data) => {
      this.datas = data;
      this.Client = this.datas.data;
    });
    this.service.getPayment().subscribe((data) => {
      this.datas = data;
      this.Payment = this.datas.data;
    });
    this.searchTextSubject
      .pipe(
        debounceTime(1000), // Emit after 1 second of idle time
        distinctUntilChanged() // Only emit if the value has changed
      )
      .subscribe((value) => {
        console.log(value);
        // this.onFilter.emit({ Name: value }); // Emit the event after the delay
        this.GetAll();
      });
  }

  
  onSearchTextChanged() {
    console.log(this.enteredSearchValue);
    this.searchTextSubject.next(this.enteredSearchValue);
  }

  onCoordinatorSelected() {
    console.log('coordinator selected');
    // this.onFilter.emit({ Coordinator: this.CoordinatorSelected });
    console.log({ Coordinator: this.CoordinatorSelected });
  }

  onRoleSelected() {
    // this.onFilter.emit({ Role: this.roleSelected });
  }

  onClientSelected() {
    // this.onFilter.emit({ Client: this.ClientSelected });
  }

  onStateSelected() {
    // this.onFilter.emit({ state: this.StateSelected });
  }

  onStartDateChanged() {
    if (this.range.value.end) {
      // alert(this.range.value.start)
      let date = this.range.value.end.getDate();
      let month = this.range.value.end.getMonth();
      let year = this.range.value.end.getFullYear();
      this.create_date_lt = `${year}-${month + 1}-${date}`;
      // this.onFilter.emit({ create_date_lt: this.create_date_lt });
    } else if (this.range.value.start) {
      // alert(this.range.value.start)
      let date = this.range.value.start.getDate();
      let month = this.range.value.start.getMonth();
      let year = this.range.value.start.getFullYear();
      this.create_date_gt = `${year}-${month + 1}-${date}`;
      // this.onFilter.emit({ create_date_gt: this.create_date_gt });
    }
  }

  clearIndividual(selected: string) {
    switch (selected) {
      case 'coordinator':
        this.CoordinatorSelected = '';
        // this.onFilter.emit('clearcoordinator');
        break;
      case 'client':
        this.ClientSelected = '';
        // this.onFilter.emit('clearclient');
        break;
      case 'state':
        this.StateSelected = '';
        // this.onFilter.emit('clearstate');
        break;
      case 'searchvalue':
        this.enteredSearchValue = '';
        // this.onFilter.emit('clearsearchvalue');
        break;
      case 'startDate':
        this.create_date_gt = '';
        this.create_date_lt = '';
        this.range.get('start')?.setValue(null);
        this.range.get('end')?.setValue(null);
        // this.onFilter.emit('clearstartendDate');
        break;
      case 'endDate':
        this.create_date_gt = '';
        this.create_date_lt = '';
        this.range.get('start')?.setValue(null);
        this.range.get('end')?.setValue(null);
        // this.onFilter.emit('clearstartendDate');
        break;
      case 'coordinatorRole':
        this.roleSelected = '';
        // this.onFilter.emit('clearRole');
        break;
      default:
        break;
    }
    this.GetAll();
  }

  clearFilter() {
    this.CoordinatorSelected = '';
    this.ClientSelected = '';
    this.StateSelected = '';
    this.PaymentSelected = '';
    this.enteredSearchValue = '';
    this.create_date_gt = '';
    this.create_date_lt = '';
    this.roleSelected = '';
    this.range.get('start')?.setValue(null);
    this.range.get('end')?.setValue(null);

    // this.onFilter.emit('clear');
    this.GetAll();
  }

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  GetAll() {
    let params: any = {
      page: (+this.pageIndex + 1).toString(),
      list_size: this.pageSize.toString(),
      // role: this.role,
      // state: this.state,
      name: this.enteredSearchValue,
      // project_coordinator: this.coordinator,
      // client: this.client,
      // created_date_gt: this.startDate,
      // created_date_lt: this.endDate,
      // status: this.status,
    };
    console.log('params', params);
    if (this.enteredSearchValue === '') {
      delete params['name'];
    }
    // if (this.coordinator === '') {
    //   delete params['project_coordinator'];
    // }
    // if (this.state === '') {
    //   delete params['state'];
    // }
    // if (this.client === '') {
    //   delete params['client'];
    // }
    // if (this.startDate === '') {
    //   delete params['created_date_gt'];
    // }
    // if (this.endDate === '') {
    //   delete params['created_date_lt'];
    // }
    // if (this.role === '') {
    //   delete params['role'];
    // }
    // this.getProjectList(params);
    this.apiService
      .getProjectListByClient(params)
      .subscribe((data) => {
        this.data = data;

      this.totalItems = this.data.totalItems;
      this.dataSource = this.data.data;

      if (this.matPaginator) {
        this.dataSource.paginator = this.matPaginator;
      }
      this.dataSource.sort = this.matSort;

      this.auth.idTokenClaims$.subscribe((idToken) => {
        this.idTokenData = idToken.__raw;
        console.log(this.idTokenData);

        this.auth.getAccessTokenSilently().subscribe((accessToken) => {
          this.accessTokenData = accessToken;
        });
      });
      });
  }

  ngOnInit() {
    console.log('this.tableData: ', this.dataSource);
    const params={
      page:(this.pageIndex+1).toString(),
      list_size:this.pageSize.toString()
    }
    this.getProjectList(params);
  }

  getProjectList(params:any){
    console.log(params);
    this.dataSource=new MatTableDataSource();
    this.apiService.getProjectList(params).subscribe((data) => {
      this.data = data

      this.totalItems=this.data.totalItems;
      this.dataSource = this.data.data;
      
      if (this.matPaginator) {
        this.dataSource.paginator = this.matPaginator;
      }
      this.dataSource.sort = this.matSort;

      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken.__raw
        console.log(this.idTokenData)

        this.auth.getAccessTokenSilently().subscribe(accessToken => {
          this.accessTokenData = accessToken
        });
      })

    });

  }

  
  onPageChange(event:PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params={
      page:this.pageIndex+1,
      list_size:this.pageSize
    }
    this.getProjectList(params);
  }


  onDelete = (rowData: any) => {
    this.delete.emit(rowData);
    console.log(rowData)
  }

  onEdit = (rowData: any) => {
    this.edit.emit(rowData);
  }

  onView = (rowData: any) => {
    this.view.emit(rowData);
  }
  onInitiate = (rowData: any) => {
    this.initiate.emit(rowData);
  }


  onAssignUser = (rowData: any) => {
    this.assignUser.emit(rowData)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  move() {
    let redirectLocation = `${this.redirectUrl}/dashboard`
    window.location.href = `${redirectLocation}`;
  }
  rowData(e: any) {
    console.log(e)
    console.log(e.project_code)
    if(e['app']['application_code'] === 'G004V001'){
      
      let redirectLocation = `${this.watergrihaUrl}/admin/${e.project_code}`;
      window.location.href = `${redirectLocation}`;
    }else{
    let redirectLocation = `${this.redirectUrl}/project-details/${e.project_code}`
    console.log(redirectLocation)
    
    window.location.href = `${redirectLocation}`;
  }
}
  redirectToUrl(): void {
    // const params = new HttpParams().set('token', this.idTokenData).set('projectCode', this.projectCode)
    // console.log(params)
    //
    // this.router.navigateByUrl()

    // const myObject = {
    //   param1: `${this.idTokenData.__raw}`,
    //   param2: `${this.projectCode}`
    // };

    // const params = new HttpParams({ fromObject: myObject });
    // this.http.get('http://localhost:4200/project-detail', { params }).subscribe(response => {
    //   console.log(response)
    // });
  }

}
