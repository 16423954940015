import { Component } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Auth0 Angular SDK Sample';
  datas: any;
  rawIdToken: any;
  idTokenData: any;
  accessTokenData: any;
  userInfo: any;
  role: any;
  emailId: string;

  constructor(private service: ApiService, public auth: AuthService,
    private router: Router) { }

  ngOnInit() {


    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
    if (isAuthenticated) {

      this.auth.getAccessTokenSilently().subscribe(accessToken => {
        this.accessTokenData = accessToken
        console.log('accessToken', this.accessTokenData)
        localStorage.setItem('accessToken', this.accessTokenData)
      });
      
      this.auth.idTokenClaims$.subscribe(idToken => {
        this.idTokenData = idToken
        console.log('idToken', this.idTokenData)
        localStorage.setItem('idToken', this.idTokenData.__raw)

        this.emailId = this.idTokenData.username
        console.log(this.emailId)
        this.getUser(this.emailId)
      });

    } else{
      this.router.navigate([environment.logoutUrl])
    }
  });
  }

  getUser(emailId: string) {
    console.log(emailId);
    this.service.getUserInfo(emailId).subscribe((res) => {
      this.datas = res
      this.userInfo = this.datas.data[0]
      this.role = this.userInfo?.role_name
      console.log(this.userInfo, this.role)
      localStorage.setItem('userInfo', this.userInfo)
      localStorage.setItem('role',this.role);
      const userStringify = JSON.stringify(this.userInfo.email);
      if (this.role == 'Admin') {
        localStorage.setItem('adminInfo', this.userInfo._id);
        this.router.navigate(['/admin'])
      } else if (this.role == 'Coordinator') {
        localStorage.setItem('coordinatorInfo', this.userInfo._id);
        this.router.navigate(['/coordinator']);
      } else if (this.role == 'Client') {
        localStorage.setItem('clientInfo', this.userInfo._id);
        this.router.navigate(['/client']);
      } else if(this.role=='Evaluator'){
        localStorage.setItem('evaluatorInfo',this.userInfo._id);
        this.router.navigate(['/evaluator'])
      } else if (this.role == 'Super-Admin') {
        localStorage.setItem('adminInfo', this.userInfo._id);
        this.router.navigate(['/super-admin'])
      }else if(this.role=='Consultant'){
        localStorage.setItem('clientInfo', this.userInfo._id);
        this.router.navigate(['/client']);
      }

    })
  }


}
